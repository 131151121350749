import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Card } from 'react-bootstrap';

import bodenVerlegeIMG from '../../img/boden_verlegen.jpeg';
import edwMontageIMG from '../../img/edw_montage.jpg';
import schreinerIMG from '../../img/schreinerarbeiten.jpg';
import trockenbauIMG from '../../img/tockenbau.jpg';
import tuerMontageIMG from '../../img/tuer_montage.jpg';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';

import './SlideCards.css';

function SlideCards() {
    return (
        <>
            <Container fluid className="">
                <p className="fs-2 service-name">Unsere Dienste</p>
            </Container>
            <Swiper
                // install Swiper moduless
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={15}
                slidesPerView={1}
                navigation
                pagination={{ dynamicBullets: true }}
                scrollbar={{ draggable: true }}
            >
                <SwiperSlide>
                    <Card className="w-75 mx-auto">
                        <Card.Img id="card-img" variant="top" src={tuerMontageIMG} />
                        <Card.Body >
                            <Card.Title>Tür Montage</Card.Title>
                            <p className="fs-6 card-text">
                                Ob zuschneiden oder befestigen von Zarge,
                                montieren von Schlössern etc.
                                Wir montieren Ihnen die komplette
                                Tür mit allem Zubehör.
                            </p>
                            <ListGroup className="mt-3">
                                <ListGroup.Item>Innentüren</ListGroup.Item>
                                <ListGroup.Item>Wohnung Eingangstüren</ListGroup.Item>
                            </ListGroup>
                        </Card.Body>
                    </Card>
                </SwiperSlide>
                <SwiperSlide>
                    <Card className="w-75 mx-auto">
                        <Card.Img id="card-img" variant="top" src={bodenVerlegeIMG} />
                        <Card.Body>
                            <Card.Title>Boden Verlegen</Card.Title>
                            <p className="fs-6 card-text">
                                Nicht nur im Bereich von Türen  sind
                                wir ihr fachlich kompetenter Ansprechpartner,
                                sondern auch im Bereich Bodenbelägen.
                            </p>
                            <ListGroup className="mt-3">
                                <ListGroup.Item>Klick Laminat</ListGroup.Item>
                                <ListGroup.Item>Klick Vinyl</ListGroup.Item>
                                <ListGroup.Item>Klick Parkett</ListGroup.Item>
                            </ListGroup>
                        </Card.Body>
                    </Card>
                </SwiperSlide>
                <SwiperSlide>
                    <Card className="w-75 mx-auto">
                        <Card.Img id="card-img" variant="top" src={schreinerIMG} />
                        <Card.Body>
                            <Card.Title>Schreinerarbeiten</Card.Title>
                            <p className="fs-6 card-text">
                                Ob Terrassendiele Boden,
                                Möbel, Ausschnitte an Küchenmöbel oder
                                wechseln der Küchenarbeitsplatte! sprechen Sie uns an.
                            </p>
                            <ListGroup className="mt-3">
                                <ListGroup.Item>Möbel</ListGroup.Item>
                                <ListGroup.Item>Küche</ListGroup.Item>
                                <ListGroup.Item>Terrasse</ListGroup.Item>
                            </ListGroup>
                        </Card.Body>
                    </Card>
                </SwiperSlide>
                <SwiperSlide>
                    <Card className="w-75 mx-auto">
                        <Card.Img id="card-img" variant="top" src={trockenbauIMG} />
                        <Card.Body>
                            <Card.Title>Trockenbau</Card.Title>
                            <p className="fs-6 card-text">
                                Mit nichttragende Systemwänden und Decken unter
                                Berücksichtigung Ihrer Anforderungen in Bezug auf
                                Schallschutz und Wärmeschutz sind Sie bei uns richtig.
                            </p>
                            <ListGroup className="mt-3">
                                <ListGroup.Item>Abhängen der Decke für Lüftung, Licht etc.</ListGroup.Item>
                                <ListGroup.Item>Trennwand, Installationswand und Wandverkleidung</ListGroup.Item>
                            </ListGroup>
                        </Card.Body>
                    </Card>
                </SwiperSlide>
                <SwiperSlide>
                    <Card className="w-75 mx-auto">
                        <Card.Img id="card-img"  variant="top" src={edwMontageIMG} />
                        <Card.Body className="">
                            <Card.Title>EDV-Montage</Card.Title>
                            <p className="fs-6 mb-2 card-text">
                                Zertifizierter Industrie PC-
                                und Netzwerkfachkraft. Installation und Inbetriebnahme von Videoüberwachungs-Systeme
                            </p>
                            <ListGroup className="mt-3">
                                <ListGroup.Item>Serverschrank</ListGroup.Item>
                                <ListGroup.Item>Aktive/Passive Komponente</ListGroup.Item>
                            </ListGroup>
                        </Card.Body>
                    </Card>
                </SwiperSlide>
            </Swiper>
        </>
    )
}

export default SlideCards