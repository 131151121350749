import React from 'react'
import './style.css';

function GoogleReview(props) {
  return (
    <div className="google-review"> 
      <p className="fs-4 p-1 mb-2 font-weight-bold">Google Review</p>
      <div className="elfsight-app-5d6dd193-85c1-4899-ba08-2ce15d0b306e google-em"></div>
    </div>
  )
}


export default GoogleReview
