import React from 'react'

import Container from 'react-bootstrap/Container';
import NavbarLayout from '../components/NavbarLayout/NavbarLayout';
import Row from 'react-bootstrap/Row';
import InstaFeeds from '../components/igEmbed/InstaFeed';
import SlideCards from '../components/SlideCards/SlideCards';
import SlideKoopList from '../components/SlideKoopList/SlideKoopList';
import SlideMarkenList from '../components/SlideMarkenList/SlideMarkenList';
import CustomFooter from '../components/Footer/CustomFooter';


import home_img from '../img/home_img.jpeg'
import logo from '../img/logo.png';
import GoogleReview from '../components/GoogleReview/GoogleReview';

function Home() {
    return (
        <>
            <NavbarLayout />
            <Container fluid>
                <Container className="bg-container p-2 d-flex justify-content-around align-self-center">
                    <img id="home_img" src={home_img} alt="Logo" />
                </Container>
                <span  id="startueberuns" className="w-100 p-2 "></span>
                <Container className="bg-container rsp-split">
                    <Container className="d-flex justify-content-around align-self-center">
                        <img id="logo_WelcomePanel" src={logo} alt="LOGO"></img>
                    </Container>
                    <Container className="justify-content-around align-self-center">
                        <Row>
                            <p className="fs-4 p-2 font-weight-bold" >Herzlich Willkommen bei KURT Montage®️!</p>
                        </Row>
                        <Row>
                            <p className="fs-6 p-2 font-weight-normal">
                                Unsere Expertise in den Bereichen Türmontage, Trockenbau sowie Bodenverlegung spiegelt
                                sich in unserer Leidenschaft wider. Seit 2020 bieten wir Produkte und Dienstleistungen
                                in höchster Qualität an und erfüllen jeden Wunsch unserer Kunden.
                                Unsere Motivation und Kreativität lebt von dem Ziel, Ihnen durch eine kompetente
                                Beratung und ein breites Leistungsspektrum ein Lächeln auf das Gesicht zu zaubern.
                                Um Ihnen den bestmöglichen Service anbieten zu können, sind wir immer auf der Suche
                                nach neuen innovativen Wegen und bilden uns stetig weiter.</p>
                        </Row>
                    </Container>
                </Container>
            </Container>
            <span className="w-100 p-2"  ></span>
            <Container fluid>
                <Container className="bg-container p-3">
                    <InstaFeeds limit={20} token={process.env.REACT_APP_IG_TOKEN} />
                </Container>
            </Container>
            <span className="w-100 p-2" id='unsere-dienste' ></span>
            <Container fluid>
                <Container className="bg-container p-3">
                    <SlideCards></SlideCards>
                </Container>
            </Container>
            <span className="w-100 p-2"></span>
            <Container fluid>
                <Container className="bg-container p-3" >
                    <p className="fs-4 p-1 font-weight-bold">Unsere Lieferanten und Kooprationspartner</p>
                    <div>
                        <SlideKoopList></SlideKoopList>
                    </div>
                </Container>
            </Container>
            <span className="w-100 p-2"></span>
            <Container fluid>
                <Container className="bg-container p-3" >
                    <p className="fs-4 p-1 font-weight-bold">Marken die wir bevorzugen</p>
                    <div>
                        <SlideMarkenList></SlideMarkenList>
                    </div>
                </Container>
            </Container>
            <span className="w-100 p-2"></span>
            <Container fluid>
                <Container className="bg-container p-3" >
                    <GoogleReview></GoogleReview>
                </Container>
            </Container>
            <span className="w-100 p-2" id='kontakt'></span>
            <CustomFooter></CustomFooter>


        </>
    )
}

export default Home