
import '../style/LinkListStart.css'
import Nav from 'react-bootstrap/Nav';
import icon_facebook from '../img/icon_facebook.png';
import icon_google from '../img/icon_google.png';
import icon_instagram from '../img/icon_instagram.png';
import icon_whatsapp from '../img/icon_whatsapp.png';
import icon_home from '../img/icon_HOME.png';
import logo from '../img/logo.png';

function LinkListStart() {
  return (
    <div className="LinkListStart">
      <div className="LinkListStart-logo">
        <img id="LinkListStart-logo" src={logo} alt="Kurtmontage" />
      </div>
      <div className="LinkList">
        <Nav.Link className="row-lli" href="/">
          <div className="linklistItem">
            <img className="ic"
              src={icon_home}
              alt="icon_home"
            />
            <p className="text-center fs-5 color-text-lls fw-normal ">Startseite</p>
          </div>
        </Nav.Link>
        <Nav.Link className="row-lli" href="/">
          <div className="linklistItem">
            <img className="ic"
              src={icon_facebook}
              alt="icon_facebook"
            />
            <p className="text-center fs-5 color-text-lls fw-normal ">Facebook</p>
          </div>
        </Nav.Link>
        <Nav.Link className="row-lli" href="#startueberuns">
          <div className="linklistItem">
            <img className="ic"
              src={icon_instagram}
              alt="icon_instagram"
            />
            <p className="text-center fs-5 color-text-lls fw-normal">Instagram</p>
          </div>
        </Nav.Link>
        <Nav.Link className="row-lli" href="#unsere-dienste">
          <div className="linklistItem">
            <img className="ic"
              src={icon_whatsapp}
              alt="icon_whatsapp"
            />
            <p className="itemText-lli text-center fs-5 color-text-lls fw-normal">Whatsapp</p>
          </div>
        </Nav.Link>
        <Nav.Link className="row-lli" href="#kontakt">
          <div className="linklistItem">
            <img className="ic"
              src={icon_google}
              alt="icon_google"
            />
            <p className='text-center fs-5 color-text-lls fw-normal'>Google  </p>
          </div>
        </Nav.Link>
      </div>
    </div>
  );
}

export default LinkListStart;
