import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import Nav from 'react-bootstrap/Nav';

function SlideKoopList() {
    return (
        <div>

            <Swiper
                // install Swiper moduless
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={10}
                slidesPerView={2}
                navigation
                pagination={{ dynamicBullets: true, clickable: true }}
                scrollbar={{ draggable: true }}
            >
                <SwiperSlide>
                    <div className="d-flex justify-content-center align-items-center max-sizing" >
                        <Nav.Link href="https://www.kemmler.de/">
                            <img id="koop-img" src="https://www.kemmler.de/images/icons/kemmler-logo.svg" alt='Kemmler Baustoffe GmbH Logo' />
                        </Nav.Link>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="d-flex justify-content-center align-items-center max-sizing" >
                        <Nav.Link href="https://www.cetin-it.de/">
                            <img id="koop-img" src="https://www.cetin-it.de/wp-content/uploads/2019/10/Logo-helles-Grau.png" alt='Cetin IT Logo' />
                        </Nav.Link>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="d-flex justify-content-center align-items-center max-sizing">
                        <Nav.Link href="https://www.koempf24.de/">
                            <img id="koop-img" src="https://assets.koempf24.de/shop/koempf24_de.svg" alt='Kömpf24 Logo' />
                        </Nav.Link>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="d-flex justify-content-center align-items-center max-sizing" >
                        <Nav.Link href="https://www.dieterle-wohnideen.de/">
                            <img id="koop-img" src="https://www.dieterle-wohnideen.de/fileadmin/user_upload/Partner_Dieterle/00_pool/dieterle-logo-300x105.png" alt='dieterle-wohnideen Logo' />
                        </Nav.Link>
                    </div>
                </SwiperSlide>
            </Swiper>

        </div>
    )
}

export default SlideKoopList