import React from 'react';


const Feed = (props) => {
    const {caption, media_type, media_url } = props.feed


    let post;

    switch (media_type) {
        case "VIDEO":
            break;
        case "CAROUSEL_ALBUM":
            post = (
                <img
                    width='100%'
                    height='auto'
                    id="ig_img"
                    src={media_url}
                    alt={caption}
                />
            );
            break;
        default:
            post = (
                <img
                    width='100%'
                    height='auto'
                    id="ig_img"
                    src={media_url}
                    alt={caption}
                />
            );
    }

    return (
        <React.Fragment >
            {post}
        </React.Fragment>
    );
}

export default Feed;