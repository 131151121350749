import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function Impressum() {
    return (
        <Container className="bg-datenschutz">
            <Row>
                <Col>
                    <h1 className="fs-2 mt-1">Impressum</h1>
                </Col>
            </Row>
            <Row>
                <p className="fs-4">Angaben gemäß § 5 TMG</p>
            </Row>
            <Row>
                <p className="fs-6">Abdurrahman Kurt</p>
                <p className="fs-6">Kurt Montage® (Einzelunternehmer)</p>
                <p className="fs-6">Haggasse 3</p>
                <p className="fs-6">75365 Calw</p>
            </Row>
            <Row>
                <p className="fs-4">Kontakt</p>
            </Row>
            <Row>
                <p className="fs-6">Telefon: +49 (0) 7051 81 60 192</p>
                <p className="fs-6">E-Mail: info@kurt-montage.de</p>
            </Row>
            <Row>
                <p className="fs-4">Gewerbeanmeldung</p>
            </Row>
            <Row>
                <p className="fs-6">Die Gewerbeanmeldung nach § 14 oder §55c GewO wurde am 03.01.2020 durch Ordnungsamt Calw erteilt.</p>
            </Row>
            <Row>
                <p className="fs-4">Redaktionell verantwortlich</p>
            </Row>
            <Row>
                <p className="fs-6">Abdurrahman Kurt</p>
                <p className="fs-6">Haggasse 3</p>
                <p className="fs-6">75365 Calw</p>
            </Row>
            <Row>
                <p className="fs-4">EU-Streitschlichtung</p>
            </Row>
            <Row>
                <p className="fs-6">Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: https://ec.europa.eu/consumers/odr/. Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>
            </Row>
            <Row>
                <p className="fs-4">Verbraucherstreitbeilegung/Universalschlichtungsstelle</p>
            </Row>
            <Row>
                <p className="fs-6">Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
            </Row>
            
            <p className="fs-6">Quelle: e-recht24.de</p>
            <Link className="fs-5 footer-link-2" to="/">Startseite</Link>
        </Container>
    )
}

export default Impressum