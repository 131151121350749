import React, { useState, useEffect, useRef } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { FaInstagram } from 'react-icons/fa'
import axios from 'axios';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import Container from 'react-bootstrap/Container';
import Feed from './Feed';
import Navbar from 'react-bootstrap/Navbar';
import setup_config from "../../setup_config.json";

// Import Swiper styles
import 'swiper/css';
import './InstaFeed.css';




function InstaFeeds({ token, ...props }) {
    const [feeds, setFeedsData] = useState([])

    //use useRef to store the latest value of the prop without firing the effect
    const tokenProp = useRef(token);
    tokenProp.current = token;
    const [show, setShow] = useState(false);
    const [isLoaded, setLoaded] = useState(false);


    const handleToogle = () => {
        setShow(!show);
    }

    useEffect(() => {
        // this is to avoid memory leaks
        const abortController = new AbortController();

        async function fetchInstagramPost() {
            try {
                setLoaded(true);
                axios
                    .get(`https://graph.instagram.com/me/media?fields=key,id,media_type,media_url,caption&limit=${props.limit}&access_token=${tokenProp.current}`)
                    .then((resp) => {
                        setFeedsData(resp.data.data);
                        setLoaded(true);
                    })
                    .catch((err) => {
                        console.log('Error');
                        setLoaded(true);
                        setFeedsData(null);
                    })

            } catch (err) {
                console.log('error', err)
                setLoaded(false);
                setFeedsData(null);
            }
        }

        function preparePost(feeds) {
        }

        // manually call the fecth function 
        fetchInstagramPost();
        preparePost();

        return () => {
            // cancel pending fetch request on component unmount
            abortController.abort();
        };
    }, [props.limit])

    const renderFeedsLogic = () => {
        if (isLoaded) {
            if (feeds == null) {
                return (
                    <div className="p-4">
                        <p className='fs-5 text-center'>Aufgrund einer technischen Störung kann diese Anzeige nicht geladen werden. Sie können sich unsere aktuellen Referenzen auch über den folgenden Link anschauen.</p>
                        <Navbar.Brand className='p-2' href={setup_config.social_link_setup.instagram_setup.href}>
                            <h3 className="text-center fs-4">Link zur Instagram</h3>
                        </Navbar.Brand>
                    </div>
                )
            } else {
                return (
                    <div className="">
                        <Swiper
                            spaceBetween={15}
                            slidesPerView={show ? 1 : 2}
                            scrollbar={{ draggable: true }}
                        >
                            {
                                feeds.filter(feed => feed.media_type !== "VIDEO").map((feed) => (
                                    <div className="p-1">
                                        <SwiperSlide>
                                            <div>
                                                <div onClick={handleToogle} className="ig-bodyCard justify-content-around align-self-center w-75 mx-auto">
                                                    <Feed id={feed.id} feed={feed} />
                                                    <p className=" text-justify ig-text p-1" >{feed.caption}</p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    </div>
                                ))
                            }
                        </Swiper>
                    </div>
                )
            }
        } else {
            return (
                <div className="">
                    <h1>Loading...</h1>
                </div>
            )
        }
    }

    return (
        <>
            <div className="bg-ig-post round-border">
                <Container className=" d-flex flex-row p-2 justify-content-start align-items-center ">
                    <Navbar.Brand href={setup_config.social_link_setup.instagram_setup.href}>
                        <FaInstagram id='ssl-icon-ig'></FaInstagram>
                    </Navbar.Brand>
                    <p className="fs-3 bg-ig-titel">Instagram Feed</p>
                </Container>
                {
                    renderFeedsLogic()
                }
            </div>
        </>
    );
}

export default InstaFeeds;