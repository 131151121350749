import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import Nav from 'react-bootstrap/Nav';
import setup_config from "../../setup_config.json";

const slider_koopPatner_setup = setup_config.slider_koopPatner_setup;

function SlideKoopList() {
    return (
        <div>
            <Swiper
                // install Swiper moduless
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={10}
                slidesPerView={2}
                navigation
                pagination={{ dynamicBullets: true, clickable: true }}
                scrollbar={{ draggable: true }}
            >
                {
                    slider_koopPatner_setup.slide_item.map((item) => {
                        return (
                            <SwiperSlide key={item.key}>
                                <div className="d-flex justify-content-center align-items-center max-sizing" >
                                    <Nav.Link href={item.href}>
                                        <img id="koop-img" src={item.img_refPath} alt={item.alt} />
                                    </Nav.Link>
                                </div>
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>

        </div>
    )
}

export default SlideKoopList