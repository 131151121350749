import React from 'react'

import logo from '../../img/logo.png';
import setup_config from "../../setup_config.json";

import icon_facebook from '../../img/icon_facebook.png';
import icon_google from '../../img/icon_google.png';
import icon_instagram from '../../img/icon_instagram.png';
import icon_whatsapp from '../../img/icon_whatsapp.png';
import { Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';

function CustomFooter() {
    const social_link_setup = setup_config.social_link_setup;

    return (<>
        <footer className="text-center text-lg-start bg-footer p-2">
            <section className="">
                <div className="container text-center text-md-start mt-5 pb-4">
                    <div className="row">


                        <div className="col-7 col-sm-6 col-md-4 col-lg-2 mx-auto pt-2">
                            <img id="footer-logo" src={logo} alt="LOGO"></img>
                        </div>
                        <div className="col-md-12 col-lg-4 pt-2 pb-4">
                            <p className="footer-text h-100 w-100  text-center">Wir sind ihr kompetenter und zuverlässiger Partner in Sachen Handwerk.</p>
                        </div>

                        <div className="col-md-12 col-lg-6 col-xl-6 pt-2">
                            <div className="kontact-content">
                                <h6 className="text-uppercase fw-bold text-center fs-2">Kontakt</h6>
                                <div className="ssl-row center">
                                <div className='ssl-item'>
                                        <Navbar.Brand href={social_link_setup.instagram_setup.href}>
                                            <img href={social_link_setup.instagram_setup.href} id="ssl-icon" src={icon_instagram} alt={"icon Instagram"}></img>
                                        </Navbar.Brand>
                                    </div>
                                    <div className='ssl-item'>
                                        <Navbar.Brand href={social_link_setup.facebook_setup.href}>
                                            <img href={social_link_setup.facebook_setup.href} id="ssl-icon" src={icon_facebook} alt={"icon facebook"}></img>
                                        </Navbar.Brand>
                                    </div>
                                    <div className='ssl-item'>
                                        <Navbar.Brand href={social_link_setup.google_setup.href}>
                                            <img href={social_link_setup.google_setup.href} id="ssl-icon" src={icon_google} alt={"icon_google"}></img>
                                        </Navbar.Brand>
                                    </div>

                                    <div className='ssl-item'>
                                        <Navbar.Brand href={social_link_setup.whatsapp_setup.href}>
                                            <img href={social_link_setup.whatsapp_setup.href} id="ssl-icon" src={icon_whatsapp} alt={"icon whatsapp"}></img>
                                        </Navbar.Brand>
                                    </div>
                                </div>

                                <p className="text-center"><i className="fas fa-envelope mr-3 center"></i>  info@ihr-monteur.com</p>
                                <p className="text-center"><i className="fas fa-envelope mr-3"></i>  info@kurt-montage.de</p>
                                <p className="text-center"><i className="fas fa-phone mr-3"></i> +49 7051 81 60 192</p>
                                <p className="text-center"><i className="fas fa-print mr-3"></i> +49 176 63 47 69 12</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </footer>
        <div className="row w-100">
            <div className="col d-flex justify-content-center">
                <Link className="footer-link" to="/datenschutz">Datenschutz</Link>
            </div>
            <div className="col d-flex justify-content-center">
                <Link className="footer-link" to="/impressum">Impressum</Link>
            </div>
        </div>
    </>
    )
}

export default CustomFooter