import React from 'react'
import { useState } from "react";

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import SocialLinkList from '../socialLinkedList/SocialLinkList';

import logo from '../../img/logo.png';
import setup_config from "../../setup_config.json";

function NavbarLayout() {
    const [show, setShow] = useState(false);

    function toggleOffCanvas() {
        setShow((show) => !show);
    }

    function toggleClose() {
        if (show === true) {
            setShow((show) => false);
        }
    }

    const nav_config = setup_config.nav_config;

    return (
        <Navbar key={nav_config.expand_on_size} expand={nav_config.expand_on_size} className="mb-3" fixed={nav_config.nav_fixed}>
            <Container fluid>

                <Navbar.Brand className="align-items-center" href="/">
                    <img id="logo_navbar" src={logo} alt="Logo" />
                </Navbar.Brand>

                <Navbar.Toggle onClick={toggleOffCanvas} aria-controls={`offcanvasNavbar-expand-${nav_config.expand_on_size}`} />

                <Navbar.Offcanvas
                    id={`${nav_config.nav_offcanvas.id}${nav_config.expand_on_size}`}
                    aria-labelledby={`offcanvasNavbarLabel-expand-${nav_config.expand_on_size}`}
                    show={show}
                    placement={nav_config.nav_offcanvas.placement}>
                    <Offcanvas.Header className="align-items-center justify-content-center">
                        <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${nav_config.expand_on_size}`}>

                            <Navbar.Brand className="align-items-center" href="/">
                                <img href="/" id="logo_navbar_slide" src={logo} alt="Logo" />
                            </Navbar.Brand>
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body className="d-flex row justify-content-around ">
                        <Nav className="align-items-center justify-content-around flex">
                            {
                                nav_config.nav_items.map(item => {
                                    return (<Nav.Link key={item.id} onClick={toggleClose} className={nav_config.nav_item_style} href={item.href}>{item.value}</Nav.Link>)
                                })
                            }
                            <SocialLinkList></SocialLinkList>
                        </Nav>

                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Container>
        </Navbar>
    )
}

export default NavbarLayout