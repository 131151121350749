import { Route, Routes } from 'react-router-dom';

import Home from './pages/Home';
import NotFound from './pages/NotFound';
import LinkListStart from './pages/LinkListStart';
import Datenschutz from './pages/Datenschutz';

import './style/Layout.css';
import Impressum from './pages/Impressum';



function App() {
  
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="*" element={<NotFound />} />
      <Route path="/start" element={<LinkListStart />} />
      <Route path="/datenschutz" element={<Datenschutz />} />
      <Route path="/impressum" element={<Impressum />} />
    </Routes>
  );
}


export default App;


