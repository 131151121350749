// React imports
import React from 'react';

import './style.css';
import setup_config from "../../setup_config.json";


import icon_facebook from '../../img/icon_facebook.png';
import icon_google from '../../img/icon_google.png';
import icon_instagram from '../../img/icon_instagram.png';
import icon_whatsapp from '../../img/icon_whatsapp.png';
import Navbar from 'react-bootstrap/Navbar';

const social_link_setup = setup_config.social_link_setup;

// NavbarLink component Loaded by Navbar: has 4 Icon from SocialNet
function SocialLinkList() {
    return (
        <div className="socialLinkedListMenu">
            <div className="ssl-row">
                <div className='ssl-item'>
                    <Navbar.Brand href={social_link_setup.instagram_setup.href}>
                        <img id="ssl-icon" src={icon_instagram} alt={"icon Instagram"}></img>
                    </Navbar.Brand>
                </div>
                <div className='ssl-item'>
                    <Navbar.Brand href={social_link_setup.facebook_setup.href}>
                        <img id="ssl-icon" src={icon_facebook} alt={"icon facebook"}></img>
                    </Navbar.Brand>
                </div>
            </div>
            <div className="ssl-row">
                <div className='ssl-item'>
                    <Navbar.Brand href={social_link_setup.google_setup.href}>
                        <img id="ssl-icon" src={icon_google} alt={"icon_google"}></img>
                    </Navbar.Brand>
                </div>
                <div className='ssl-item'>
                    <Navbar.Brand href={social_link_setup.whatsapp_setup.href}>
                        <img id="ssl-icon" src={icon_whatsapp} alt={"icon whatsapp"}></img>
                    </Navbar.Brand>
                </div>
            </div>

        </div>
    )
}

export default SocialLinkList